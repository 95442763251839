import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

export const selectCurrentDate = createSelector(
  (state: RootState) => state.schedule,
  (schedule) => ({ month: schedule.currentMonth, year: schedule.currentYear }),
);

export const selectWeeks = createSelector(
  (state: RootState) => state.schedule.weeks,
  (weeks) => weeks,
);

export const selectLoad = createSelector(
  (state: RootState) => state.schedule,
  (schedule) => ({ isLoading: schedule.isLoading, isLoaded: schedule.isLoaded }),
);

export const selectCalendar = createSelector(
  (state: RootState) => state.schedule.calendar,
  (calendar) => calendar,
)

export const selectedDay = createSelector(
  (state: RootState) => state.schedule,
  (schedule) => {
    const selectedData = schedule.selectedDate
    const monthName = new Date(selectedData).toLocaleDateString('ru-RU', { month: 'long' })
    const days = schedule.calendar[ monthName ] ?? []
    const matches = _(days).find(({ date }) => date.getDate() === selectedData.getDate())?.matches ?? []
    return {
      date: selectedData,
      matches,
    }
  },
)

export const selectMatchById = (id: number) => createSelector(
  [selectedDay],
  ({ matches }) => matches.find((match) => match.id === id),
)

export const selectFilter = createSelector(
  (state: RootState) => state.schedule,
  (schedule) => ({ championship: schedule.championshipFilter }),
)
