import { Tab } from "../../../ud-ui/components/tabs/interfaces";

export type EditFormTabKey = 'COMMON' | 'TEAMS' /* | 'ADMINS' */;

export const PlayerEditFormPart: Record<EditFormTabKey, Tab> = {
  COMMON: { hash: 'common', label: 'Основные данные' },
  TEAMS: { hash: 'teams', label: 'Команды' },
};

export const defaultTab = PlayerEditFormPart.COMMON;
