import { MainBlock, RowsContainer } from "./styles";
import UDMainLayout from "../../../../ud-ui/layout/main";
import React, { useEffect, useMemo, useState } from "react";
import OrganizerRow from "../../components/organizer-row";
import useOrganizers from "../../hooks/useOrganizers";
import { OrganizerToCardDataMapper } from "../../../domain/data-mappers/orginizerToCard.data-mapper";
import { SyncLoader } from "react-spinners";
import Header from "../../components/header";
import { useDispatch, useSelector } from "react-redux";
import { setOrganizers } from "../../../store";
import { selectOrganizersOptions } from "../../../store/selectors";
import { filteringBySearch } from "../../../domain/utils/filteringBySearch";
import { filteringByPhone } from "../../../domain/utils/filteringByPhone";
import { Organizer } from "../../../domain/interfaces/Organizer";
import { filteringBySportType } from "../../../domain/utils/filteringBySportType";
import { sortingByDate } from "../../../domain/utils/sortingByDate";
import UDText from "../../../../ud-ui/components/text";
import ImgNotFound from '../../../../../modules/ud-ui/components/images/notFound.png'
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../../navigation/domain/enums/pages";
import UDHeaderTitle from "../../../../ud-ui/components/header-title";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";

function OrganizersList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { organizers, isLoad } = useOrganizers()
  const [processedListOrganizers, setProcessedListOrganizers] = useState({
    bySearch: [] as Organizer[],
    byPhone: [] as Organizer[],
    bySortByDate: [] as Organizer[],
    bySportType: [] as Organizer[],
  })
  const { filter, filterByNumber, sortByDate, filterBySportType } = useSelector(selectOrganizersOptions)
  const mapper = useMemo(() => {return new OrganizerToCardDataMapper()}, []);
  
  useEffect(() => {
    if (!organizers) return
    dispatch(setOrganizers(organizers))
    setProcessedListOrganizers({
      bySearch: organizers,
      byPhone: organizers,
      bySortByDate: organizers,
      bySportType: organizers,
    })
  }, [dispatch, organizers])
  
  useEffect(() => {
    const filteringResult = filteringBySearch(organizers, filter?.toLowerCase().trim()) ?? []
    setProcessedListOrganizers((prevState) => ({ ...prevState, bySearch: filteringResult }))
  }, [filter, organizers])
  
  // FilterByPhone
  useEffect(() => {
    const filteringResult = filteringByPhone(processedListOrganizers.bySearch, filterByNumber) ?? []
    setProcessedListOrganizers((prevState) => ({ ...prevState, byPhone: filteringResult }))
  }, [filterByNumber, organizers, processedListOrganizers.bySearch])
  
  useEffect(() => {
    const filteringResult = filteringBySportType(processedListOrganizers.byPhone, filterBySportType) ?? []
    setProcessedListOrganizers((prevState) => ({ ...prevState, bySportType: filteringResult }))
  }, [filterBySportType, processedListOrganizers.byPhone])
  
  useEffect(() => {
    const filteringResult = sortingByDate(processedListOrganizers.bySportType, sortByDate) ?? []
    setProcessedListOrganizers((prevState) => ({ ...prevState, bySortByDate: filteringResult }))
  }, [processedListOrganizers.bySportType, sortByDate])
  
  const organizersList = useMemo(() => {
    if (!processedListOrganizers.bySortByDate) return
    return processedListOrganizers.bySortByDate.map((organizer) => {
      const onCLick = () => {
        navigate(Pages.ORGANIZERS.builders.view(organizer.id))
      }
      
      return (
        <OrganizerRow key={organizer.id} onClick={onCLick} {...mapper.decode(organizer)} />
      )
    })
  }, [mapper, navigate, processedListOrganizers.bySortByDate]);
  
  const { user } = useAuth()
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development'
  if (user?.id !== 20 && !isDev) {
    return null
  }
  
  return (
    <UDMainLayout headerContent={<UDHeaderTitle>Организаторы</UDHeaderTitle>}>
      <MainBlock>
        <Header/>
        <RowsContainer>
          {!isLoad && <div className={'container flex-center'} style={{ height: '100%' }}><SyncLoader/></div>}
          {organizersList?.length ?
            organizersList
            : isLoad && (
            <div className={'container flex-center'} style={{ height: '100%', flexDirection: 'column' }}>
              <img style={{ width: 130, height: 130 }} alt={'not found'} src={ImgNotFound}/>
              <UDText type={'callout'} className={'color-SurfaceRomanSilver30'}>
                По такому запросу ничего не найдено
              </UDText>
            </div>
          )}
        </RowsContainer>
      </MainBlock>
    </UDMainLayout>
  );
}

export default OrganizersList;
