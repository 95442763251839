/** @jsxImportSource @emotion/react */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDMainLayout from "../../../ud-ui/layout/main";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import { selectPlayerById } from "../../store/selectors";
import { useSelector } from "react-redux";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import PlayerLoad from "../components/playerLoad";
import { Player } from "../../domain/interfaces/player";
import playersRepository from "../../domain/repositories/player.repository";
import PlayerEditFormTabs from "../components/tabs-player-view";
import UpsertPlayerForm from "../components/player-form";
import TeamsList from "../components/teams-list";
import useTournamentUsers from "../hooks/useTournamentUsers";

function PlayerViewPage() {
  const id = Number(useParams().id);
  const navigate = useNavigate();
  if (isNaN(id)) {navigate(Pages.PLAYERS.INDEX)}
  
  const location = useLocation()
  const activeTab = (location.pathname || '').split('/').at(-1);
  
  const { info } = useOrganizer()
  const [isLoading, setIsLoading] = useState(true)
  const [player, setPlayer] = useState<Player>()
  const findPlayer = useSelector(selectPlayerById(id));
  const { isProcessed } = useTournamentUsers()
  
  useEffect(() => {
    if (isProcessed) {
      playersRepository.getUser(id).then((player) => {
        setPlayer(player)
        setIsLoading(false)
      })
    }
  }, [id, isProcessed])
  
  useEffect(() => {
    if (findPlayer === undefined) {
      playersRepository.getUser(id).then((player) => {
        setPlayer(player)
        setIsLoading(false)
      })
    } else {
      setPlayer(findPlayer)
      setIsLoading(false)
    }
  }, [findPlayer, id]);
  
  const fullName = useMemo(() => {
    return player && `${player?.name} ${player?.surname} ${player?.middleName ?? ''}`;
  }, [player]);
  
  const breadcrumbs = useMemo(() => {
    const result = [
      { label: 'Игроки', to: Pages.PLAYERS.INDEX },
      { label: fullName ?? 'Неизвестный игрок', to: Pages.PLAYERS.builders.view(id) },
    ]
    
    if (activeTab === 'teams') {
      result.push({ label: 'Команды', to: Pages.PLAYERS.builders.view(id, 'teams') })
    }
    
    return result
  }, [activeTab, fullName, id]);
  
  const breadcrumb = useMemo(
    () => (
      <UDBreadcrumbs
        className='my-10'
        breadcrumbs={breadcrumbs}
      />),
    [breadcrumbs],
  );
  
  const teams = useMemo(() => {
    return player?.positionsInTeams?.map(positionInTeam => positionInTeam.team)
  }, [player?.positionsInTeams]);
  
  const body = useMemo(() => {
    if (!info || isLoading) {
      return <PlayerLoad/>
    }
    
    if (!player) {
      return (
        <div className='container pt-6'>
          <div className='flex-column flex-center mt-24'>
            <div className='text body bold mb-2'>
              Такой игрок не найден
            </div>
          </div>
        </div>
      )
    }
    
    switch (activeTab) {
      case 'teams':
        return (
          <div className='mt-6'>
            <TeamsList player={player} teams={teams ?? []}/>
          </div>
        )
      case 'common':
        return (
          <UpsertPlayerForm
            player={player}
            tournamentId={info.id}
          />
        )
      default:
        return null
    }
  }, [activeTab, info, isLoading, player, teams]);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'container pt-6'}>
        <div className={'mb-6'}>
          {player ? (
            <PlayerEditFormTabs player={player}/>
          ) : (
            <></>
          )}
        </div>
        <div>
          {body}
        </div>
      </div>
    </UDMainLayout>
  );
}

export default PlayerViewPage;
