/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Portal from "../portal";
import BaseModal from "./baseModal";
import UDIcon from "../icon";
import { DivProps } from "../../../../typings";
import { css } from "@emotion/react";

export default function useModal(props?: IProps) {
  const { onClose } = props ?? {};
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    modalStyle: {},
    iconProps: {},
    containerProps: {},
    overlayProps: {},
  });
  
  const isOpen = useMemo(() => {
    return state.isOpen;
  }, [state.isOpen]);
  
  const close = useCallback(() => {
    setState({ isOpen: false, modalStyle: {} });
    onClose?.();
  }, [onClose]);
  
  const open = useCallback((styles: styles) => {
    setState({ isOpen: true, ...styles });
  }, []);
  
  useEffect(() => {
    const onPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    }
    
    document.addEventListener('keydown', onPress);
    return () => {
      document.removeEventListener('keydown', onPress);
    }
  }, [close]);
  
  const Modal = useCallback((props: DivProps) => {
    return (
      <Portal wrapperId={Math.random().toString()}>
        <BaseModal isOpen={state.isOpen} style={{ ...state.modalStyle }}>
          <div
            style={{ position: 'absolute', top: 0, right: 0, width: '100vw', height: '100vh' }}
            onClick={close}
            {...state.overlayProps}
          />
          <div css={modalStyle} {...state.containerProps}>
            <div css={iconModalCss} {...state.iconProps} onClick={close}>
              <UDIcon
                name={'close'}
                componentProps={{
                  style: { width: 12, height: 12, cursor: 'pointer' },
                }}
              />
            </div>
            <div>
              {props.children}
            </div>
          </div>
        </BaseModal>
      </Portal>
    )
  }, [close, state.containerProps, state.iconProps, state.isOpen, state.modalStyle, state.overlayProps]);
  
  return { Modal, close, open, isOpen };
}

interface IProps {
  onClose?: () => void
}

interface ModalState extends styles {
  isOpen: boolean;
}

interface styles {
  modalStyle: Partial<React.CSSProperties>;
  containerProps?: DivProps
  overlayProps?: DivProps
  iconProps?: DivProps
}

const modalStyle = css`
  z-index: 1001;
  
  background-color: #FFF;
  border: 1px solid #EDEDED;
  box-shadow: 4px 4px 4px 0 #00000040;
  width: 500px;
  min-height: 200px;
  border-radius: 8px;
  
  font-family: SF Pro Text, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  
  display: flex;
  flex-direction: column;
  
  padding: 34px 24px;
  position: relative;
`

const iconModalCss = css`
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 22px;
  cursor: pointer;
`
