import { ScheduleSliceState } from "../index";
import { getWeekOfMonth } from "date-fns";
import getDay from "../../domain/utils/getDay";

export const markGameDays = (state: ScheduleSliceState) => {
  const monthName = new Date(state.currentYear, state.currentMonth).toLocaleDateString('ru-RU', { month: 'long' })
  const days = state.calendar[ monthName ]
  if (!days) return
  for (const day of days) {
    const { date } = day
    const isSomeYear = state.currentYear !== date.getFullYear()
    if (isSomeYear) continue
    const week = getWeekOfMonth(date, {weekStartsOn: 1})
    const weekDay = state.weeks[ week - 1 ][ getDay({ date }) ];
    weekDay.status = 'game'
  }
}
