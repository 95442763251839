import { Player } from "../domain/interfaces/player";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TournamentUsersRepository from "../domain/repositories/player.repository";
import {
  AddTournamentUserToTeam,
  FetchTournamentUsers,
  KickTournamentUserFromTeam,
} from "../domain/interfaces/actions";
import { UpsertTournamentPlayerApplicationDto } from "../../teams/domain/dtos/UpsertTournamentPlayerAction.dto.";
import { UpsertTournamentApplicationUserDto } from "../../teams/domain/dtos/UpsertTournamentApplicationUser.dto";
import playersResource from "../../teams/domain/resources/playersResource";

const PREFIX = 'players';

export const fetchTournamentUsers = createAsyncThunk<Player[], Omit<FetchTournamentUsers, 'nameFilter'>>(
  `${PREFIX}/tournamentUsers`,
  async (payload, { rejectWithValue }) => {
    try {
      return TournamentUsersRepository.fetchTournamentUsers(payload);
    } catch (error) {
      console.error(`${PREFIX}/fetchPlayers error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchTournamentUsersByFilter = createAsyncThunk<Player[], FetchTournamentUsers>(
  `${PREFIX}/fetchTournamentUsersByFilter`,
  async (payload, { rejectWithValue }) => {
    try {
      return TournamentUsersRepository.fetchTournamentUsers(payload);
    } catch (error) {
      console.error(`${PREFIX}/fetchTournamentUsersByFilter error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const upsertTournamentUser = createAsyncThunk<
  Player,
  Omit<UpsertTournamentPlayerApplicationDto, 'teamId'>
>(`${PREFIX}/upsertTournamentPlayerApplication`, async (payload) => {
  try {
    const {
      tournamentId,
      userId,
      name,
      surname,
      middleName,
      birthDate,
      photo,
      height,
      weight,
    } = payload;
    let player: UpsertTournamentApplicationUserDto = {
      name,
      surname,
      birthDate,
    };
    
    if (middleName) {
      player = { ...player, middleName };
    }
    
    if (height) {
      player = { ...player, height };
    }
    
    if (weight) {
      player = { ...player, weight };
    }
    
    if (userId) {
      player = { ...player, userId };
    }
    
    let result = await TournamentUsersRepository.upsertTournamentApplicationForUser(
      player,
      tournamentId,
    );
    const { id: playerId } = result;
    
    if (photo && photo[ 0 ]) {
      await playersResource.uploadPhoto(
        photo[ 0 ],
        playerId,
        tournamentId,
      );
    }
    
    return await TournamentUsersRepository.getUser(playerId);
  } catch
    (err) {
    console.error(`${PREFIX}/upsertTournamentPlayerApplication error:`, err);
    throw err;
  }
});


export const deleteTournamentUser = createAsyncThunk<{ status: boolean }, { userId: number }>(
  `${PREFIX}/deleteTournamentUser`,
  async (payload, { rejectWithValue }) => {
    try {
      const status = await TournamentUsersRepository.deleteTournamentUser(payload);
      return { status: status === 'ok' }
    } catch (error) {
      console.error(`${PREFIX}/deleteTournamentUser error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const addTournamentUserToTeam = createAsyncThunk<Player, AddTournamentUserToTeam>(
  `${PREFIX}/addTou`,
  async (payload, { rejectWithValue }) => {
    try {
      return await TournamentUsersRepository.addTournamentUserToTeam(payload);
    } catch (error) {
      console.error(`${PREFIX}/addTou error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const kickTournamentUserFromTeam = createAsyncThunk<boolean, KickTournamentUserFromTeam>(
  `${PREFIX}/kickTournamentUserFromTeam`,
  async (payload, { rejectWithValue }) => {
    try {
      return await TournamentUsersRepository.kickTournamentUserFromTeam(payload);
    } catch (error) {
      console.error(`${PREFIX}/kickTournamentUserFromTeam error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchTournamentUser = createAsyncThunk<Player, { userId: number }>(
  `${PREFIX}/fetchTournamentUser`,
  async (payload, { rejectWithValue }) => {
    try {
      return TournamentUsersRepository.getUser(payload.userId);
    } catch (error) {
      console.error(`${PREFIX}/fetchTournamentUser error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
