import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";

export const selectTournamentUserSlice = createSelector(
  (state: RootState) => state.tournamentUsersSlice,
  (state) => state,
);

export const selectPlayers = createSelector(
  [selectTournamentUserSlice],
  (state) => state.players,
);

export const selectLoad = createSelector(
  [selectTournamentUserSlice],
  (state) => ({
    isLoading: state.isLoading,
    isLoaded: state.isLoaded,
  }),
);

export const selectPlayersByFilter = createSelector(
  [selectTournamentUserSlice],
  (state) => Boolean(state.filter.text) ? state.filter.players : state.players,
)

export const selectPlayersOnPage = createSelector(
  [selectTournamentUserSlice, selectPlayersByFilter],
  (state, players) => {
    const isFilterActive = Boolean(state.filter.text)
    const currentPage = isFilterActive ? state.filter.currentPage : state.currentPage
    return players.slice(
      (currentPage - 1) * state.playersPerPage,
      (currentPage - 1) * state.playersPerPage + state.playersPerPage,
    )
  },
);

export const selectPages = createSelector(
  [selectTournamentUserSlice],
  (state) => state.filter.text ? state.filter.pages : state.pages,
);

export const selectCurrentPage = createSelector(
  [selectTournamentUserSlice],
  (state) => {
    return state.filter.text ? state.filter.currentPage : state.currentPage
  },
);

export const selectPlayerById = (id: number) => createSelector(
  [selectPlayersOnPage],
  (players) => players.find((player) => player.id === id),
)
