import graphqlResource, { GraphqlResource } from '../../../core/graphqlResource';
import { Player } from "../interfaces/player";
import {
  AddToTeamResponseDto,
  DeleteUserResponseDto,
  GetPlayersByTournamentResponseDTO,
  GetUserResponseDto,
  KickFromTeamResponseDto,
} from "../interfaces/dto";
import { AddTournamentUserToTeam, FetchTournamentUsers } from "../interfaces/actions";
import { UpsertTournamentApplicationUserDto } from "../../../teams/domain/dtos/UpsertTournamentApplicationUser.dto";
import { TournamentUser } from "../../../teams/domain/interfaces/TournamentUser";
import {
  UpsertTournamentApplicationUserResponseDto,
} from "../../../teams/domain/dtos/UpsertTournamentApplicationUserResponse.dto";

export class PlayersRepository {
  constructor(private readonly graphql: GraphqlResource) {}
  
  public async fetchTournamentUsers(params: FetchTournamentUsers): Promise<Player[]> {
    const { tournamentId, offset = 0, nameFilter = '', limit } = params;
    
    const query = `
    query getPlayersList($id: Int!, $offset: Int!, $nameFilter: String, $limit: Int!) {
      tournament(id: $id) {
        id
        users(limit: $limit, offset: $offset, nameFilter: $nameFilter) {
          id
          name
          surname
          middleName
          birthDate
          weight
          height
          tournamentAvatars {
            id
            tournament {
              id
            }
            image {
              externalUrl
            }
          }
          photo {
            externalUrl
          }
          positionsInTeams {
            position
            number
            team {
              id
              name
              createdAt
              emblem {
                externalUrl
              }
            }
          }
        }
      }
    }
    `;
    
    const response = await this.graphql.query<GetPlayersByTournamentResponseDTO>(
      query,
      { id: tournamentId, offset, nameFilter, limit },
    );
    
    const { users } = response.data.data.tournament;
    return users;
  }
  
  public async upsertTournamentApplicationForUser(
    params: UpsertTournamentApplicationUserDto,
    tournamentId: number,
  ): Promise<TournamentUser> {
    const query = `
      mutation upsertTournamentUser($input: CreateUserInput!, $tournamentId: Int!) {
        upsertTournamentUser(input: $input, tournamentId: $tournamentId) {
          id
          name
          surname
          middleName
          birthDate
          weight
          height
          tournamentAvatars {
            id
            tournament {
              id
            }
            image {
              externalUrl
            }
          }
          photo {
            externalUrl
          }
          positionsInTeams {
            position
            number
            team {
              id
              name
              createdAt
              emblem {
                externalUrl
              }
            }
          }
        }
      }
    `;
    
    const variables = {
      input: {
        ...params,
        // position: params.position
        //   ? params.position.toUpperCase()
        //   : params.position,
      },
      tournamentId,
    };
    const response =
      await this.graphql.query<UpsertTournamentApplicationUserResponseDto>(
        query,
        variables,
      );
    const { upsertTournamentUser } = response.data.data;
    return {
      ...upsertTournamentUser,
    };
  }
  
  public async getUser(id: number): Promise<Player> {
    const query = `
      query user($id: Int!) {
        user(id: $id) {
          id
          name
          surname
          middleName
          birthDate
          weight
          height
          tournamentAvatars {
            id
            tournament {
              id
            }
            image {
              externalUrl
            }
          }
          photo {
            externalUrl
          }
          positionsInTeams {
            position
            number
            team {
              id
              name
              createdAt
              emblem {
                externalUrl
              }
            }
          }
        }
      }
    `;
    const response = await this.graphql.query<GetUserResponseDto>(query, {
      id,
    });
    
    const { user } = response.data.data;
    return user;
  }
  
  async deleteTournamentUser(payload: { userId: number }) {
    const query = `
      mutation UpsertTournamentUser($id: Int!) {
        deleteTournamentUser(userId: $id) {
            status
        }
      }
    `;
    
    const response = await this.graphql.query<DeleteUserResponseDto>(query, {
      id: payload.userId,
    });
    
    return response.data.data.deleteTournamentUser.status;
  }
  
  async addTournamentUserToTeam(payload: AddTournamentUserToTeam): Promise<Player> {
    const query = `
      mutation AddToTeam($userId: Int!, $teamId: Int!) {
          addToTeam(input: { teamId: $teamId, userId: $userId }) {
              id
              name
              surname
              middleName
              birthDate
              weight
              height
              tournamentAvatars {
                  id
                  tournament {
                      id
                  }
                  image {
                      externalUrl
                  }
              }
              photo {
                  externalUrl
              }
              positionsInTeams {
                  position
                  number
                  team {
                      id
                      name
                      createdAt
                      emblem {
                          externalUrl
                      }
                  }
              }
          }
      }
    `
    
    const response = await this.graphql.query<AddToTeamResponseDto>(query, {
      userId: payload.userId,
      teamId: payload.teamId,
    });
    
    return response.data.data.addToTeam
  }
  
  async kickTournamentUserFromTeam(payload: { userId: number; teamId: number }) {
    const query = `
      mutation KickFromTeam($userId: Int!, $teamId: Int!) {
         kickFromTeam(teamId: $teamId, userId: $userId)
      }
    `;
    
    const response = await this.graphql.query<KickFromTeamResponseDto>(query, {
      userId: payload.userId,
      teamId: payload.teamId,
    });
    
    return !response.data.errors;
  }
}

const playersRepository = new PlayersRepository(graphqlResource);

export default playersRepository;
