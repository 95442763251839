import classNames from "classnames";
import UDInput from "../../../ud-form/components/input/component";
import UDText from "../../../ud-ui/components/text";
import React, { useMemo } from "react";
import debounce from "../../../utils/debounce";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { setPlayersPerPage } from "../../store";
import useTournamentUsers from "../hooks/useTournamentUsers";

type Props = {
  className?: string;
  onFilter: (changes: any) => any;
};

function PlayerFilter(props: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { onFilter, className } = props;
  const { filter, playersPerPage } = useTournamentUsers();
  
  const handleChangeSearch = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter({
          search: e.target.value,
        });
      }, 300),
    [onFilter],
  );
  
  const handleChangeResultsOnPage = useMemo(
    () => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPlayersPerPage({ playersPerPage: +e.target.value }))
    }, [dispatch]);
  
  return (
    <div className={classNames('container py-6', className)}>
      <div className='row gx-2'>
        <div className='col-9'>
          <div className='row gx-2'>
            <div className='col-8'>
              <UDInput
                defaultValue={filter.text}
                placeholder='Поиск по игрокам'
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <div className='col-3 d-flex align-items-center'>
          <UDText
            type='subhead'
            className='color-SurfaceRomanSilver30 mr-2 ml-auto white-space-nowrap'
          >
            На странице:
          </UDText>
          <UDInput
            type='number'
            wrapperProps={{ className: 'w-30' }}
            min={1}
            value={playersPerPage}
            onChange={handleChangeResultsOnPage}
          />
        </div>
      </div>
    </div>
  );
}

export default PlayerFilter;
