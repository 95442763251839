import { useSelector } from "react-redux";
import {
  selectActiveTemplate,
  selectIsFinished,
  selectIsLoaded,
  selectIsLoading,
  selectIsProcessed,
  selectProcess,
  selectTemplates,
} from "../../store/templates/selectors";

function useTemplates() {
  const templates = useSelector(selectTemplates);
  const activeTemplate = useSelector(selectActiveTemplate)
  const isLoading = useSelector(selectIsLoading);
  const isLoaded = useSelector(selectIsLoaded);
  const isProcessed = useSelector(selectIsProcessed);
  const isFinished = useSelector(selectIsFinished);
  const currentProcess = useSelector(selectProcess)
  
  return ({
    templates,
    isLoading,
    isLoaded,
    isProcessed,
    isFinished,
    activeTemplate,
    currentProcess,
  });
}

export default useTemplates;
