import styled from "@emotion/styled";

interface IProps {
  type: 'horizontal' | 'vertical'
  color: string
  width?: string
  height?: string
}

export const Divider = styled.div<IProps>`
  &:after {
    content: '';
    display: block;
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height ?? '1px'};
    background: ${({ color }) => color};
  }
`
