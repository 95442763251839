import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js';
import { ObservableField } from "../ObservableField";
import { NewReceivedData, ReceivedEvent } from "../../domain/interfaces/supabase/receivedData";
import { Event } from "../../../stream/domain/interfaces/events";
import supabase from "../supabase";

type ConnectionType = "CLOSED" | "SUBSCRIBED" | "TIMED_OUT" | "CHANNEL_ERROR";

type TimeState = {
  seconds: number,
  period: number,
};

export type ScoreTeam = { id: number, score: number };
export type Score = [ScoreTeam, ScoreTeam]

export class MatchChronology {
  constructor(private supabase: SupabaseClient) {}
  
  chanelEvents?: RealtimeChannel
  connectionStatus: ObservableField<ConnectionType> = new ObservableField<ConnectionType>("CLOSED")
  
  lastEvent?: Event
  events: ObservableField<Event[]> = new ObservableField<Event[]>([])
  lastDateUpdate?: Date = undefined
  activeBoardId: ObservableField<number | null> = new ObservableField<number | null>(null)
  score: ObservableField<Score | null> = new ObservableField<Score | null>(null)
  currentTime: ObservableField<TimeState> = new ObservableField<TimeState>({
    seconds: 0,
    period: 0,
  })
  
  public handleRecordUpdated(data: { new: NewReceivedData, commit_timestamp: string }) {
    console.debug({ event: 'new data received', data })
    const date = new Date(data.commit_timestamp)
    if (this.lastDateUpdate === date) return
    this.lastDateUpdate = date
    
    this.checkEvents(data.new, date)
    this.checkActiveTemplate(data.new)
    this.checkTime(data.new)
    this.checkScore(data.new)
  }
  
  public async observeChanges(matchId: number) {
    this.chanelEvents = this.supabase
      .channel(`room${matchId}`)
      // @ts-ignore
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'match_chronology',
        filter: `matchId=eq.${matchId}`,
      }, this.handleRecordUpdated.bind(this))
      .subscribe((status, err) => {
        console.debug({ status })
        
        if (err) {
          supabase.overlayLogs.createError({ comment: 'Не удалось подключиться к supabase', message: err.message })
          console.error({ err })
        }
        
        this.connectionStatus.value = status
      }, 10000)
  }
  
  public async stopObserveChanges() {
    if (this.chanelEvents) {
      return await this.chanelEvents.unsubscribe()
    }
  }
  
  public async getRow(matchId: number): Promise<NewReceivedData | null> {
    const { data, error } = await this.supabase
      .from('match_chronology')
      .select()
      .eq('matchId', matchId)
      .single()
    
    if (!error) return data
    console.error(error)
    supabase.overlayLogs.createError({ comment: 'Не удалось получить данные из supabase', message: error.message })
    return null
  }
  
  private checkEvents(data: NewReceivedData, date: Date) {
    const chronology: ReceivedEvent[] = data.chronology
    this.events.value = chronology.sort((a, b) => a.id - b.id)
  }
  
  private checkActiveTemplate(data: NewReceivedData) {
    this.activeBoardId.value = data.templateId
  }
  
  private checkTime(data: NewReceivedData) {
    const { seconds, currentPeriod: period } = data
    this.currentTime.value = {
      ...this.currentTime.value,
      seconds,
      period,
    }
  }
  
  private checkScore(data: NewReceivedData) {
    const { firstTeamScore, firstTeamId, secondTeamId, secondTeamScore } = data
    this.score.value = [
      { id: firstTeamId, score: firstTeamScore },
      { id: secondTeamId, score: secondTeamScore },
    ]
  }
}
