import React, { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import UDTabs from 'modules/ud-ui/components/tabs';
import { defaultTab, TourneyEditFormPart } from './constants';
import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import UDText from '../../../../ud-ui/components/text';
import { useSelector } from 'react-redux';
import { championshipTeamsIsLoadedSelector } from '../../../store/championship/selectors';
import { Tab } from "../../../../ud-ui/components/tabs/interfaces";
import { Pages } from "../../../../navigation/domain/enums/pages";

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  subLabel?: string;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, subLabel } = params;
  
  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);
    
    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type='subhead' className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }
  
  return { ...tab, subLabel };
};

type TourneyEditFormTabsProps = {
  tourney: Championship;
};

const TourneyEditFormTabs = ({ tourney }: TourneyEditFormTabsProps) => {
  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[ 0 ];
  const isApplicationsLoaded = useSelector(championshipTeamsIsLoadedSelector);
  const isTeam = useMatch(`${Pages.TOURNEYS.EDIT}/teams/:teamId`);
  
  
  const tabs = useMemo(() => {
    return Object.values(TourneyEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        // count: (tab.hash === TourneyEditFormPart.APPLICATIONS.hash && isApplicationsLoaded)
        //   ? tourney.applications.length
        //   : undefined,
        isActive: tab.hash === activeTab,
        subLabel: tab.hash === 'teams' && isTeam ? tourney.teams.find((t) => t.id === Number(isTeam.params.teamId))?.name : undefined,
      }),
    );
  }, [tourney, activeTab, isApplicationsLoaded, isTeam]);
  
  return <UDTabs tabs={tabs} defaultTab={defaultTab}/>;
};

export default TourneyEditFormTabs;
