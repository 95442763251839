import styled from "@emotion/styled";

const defaultPhoto = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'

type PlayerPhotoContainerProps = {
  size?: number;
};

export const PlayerPhotoContainer = styled.div`
  width: ${(props: PlayerPhotoContainerProps) => props.size ?? 60}px;
  height: ${(props: PlayerPhotoContainerProps) => props.size ?? 60}px;
  
  display: flex;
  position: relative;
  border: 1px solid #EAEAEC;
  border-radius: 8px;
  justify-content: center;
  padding: 4px;
`;

type PlayerPhotoProps = {
  photoUrl?: string;
};

export const PlayerPhoto = styled.div`
  background-image: ${(props: PlayerPhotoProps) => `url('${props.photoUrl || defaultPhoto}')`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

export const TeamSmallCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  
  gap: 20px;
  
  border-radius: 8px;
  border: 1px #cccccc solid;
  background-color: #F2F2F2;
  
  transition: background-color 0.2s ease-in-out;
  
  &:hover {
    background: #ffe267;
  }
`

export const TeamSmallList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 200px;
  overflow: auto;
`
