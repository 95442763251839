import React, { useCallback, useMemo, useState } from 'react';
import { TabMenu } from '../../../../../ud-ui/components/tab-menu';
import StageInfoForm from './stage-info-form';
import TeamGroupsForm from './team-groups/team-groups-form';
import { ChampionshipStateData } from '../../../../store/championship';
import StageMatches from './matches/stage-matches';
import { useAcceptedChampionshipTeams } from '../../../hooks/use-accepted-championship-teams';
import { GameResult } from '../../../../../games/ui/components/game-result';
import StageGrid from './stage-grid/stage-grid';
import { useSelector } from 'react-redux';
import { selectedStageSelector } from '../../../../store/stages';
import Template from "./cover-templates/template";
import { Navigate } from "react-router-dom";

const defaultTabs = ['Редактирование', 'Команды', 'Сетка матчей', 'Календарь матчей'];

const extractTeamName = (gameTeam: { name: string; } | null | undefined): string => {
  if (!gameTeam) {
    return 'Неизвестно';
  }

  return gameTeam.name;
};

type StagesFormProps = {
  championship: ChampionshipStateData;
};

const StageForms = ({ championship }: StagesFormProps) => {
  const { teams } = useAcceptedChampionshipTeams();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGameId, selectGame] = useState<number | null>(null);
  const [additionalPage, setAdditionalPage] = useState<'result' | 'templates' | null>(null);

  const stage = useSelector(selectedStageSelector);

  const {
    id: championshipId,
    tournamentId,
  } = championship;

  const tabs: string[] = useMemo(() => {
    if (!selectedGameId) {
      return defaultTabs;
    }

    const game = stage?.matches.find((match) => match.id === selectedGameId);
    if (game) {
      const [firstTeam, secondTeam] = game.teams || [];

      return [
        ...defaultTabs,
        `Матч: ${extractTeamName(firstTeam)} - ${extractTeamName(secondTeam)}`,
      ];
    }

    return defaultTabs;
  }, [selectedGameId, stage?.matches]);

  const handleChangeActiveTab = useCallback((index: number) => {
    if (!stage) {
      return;
    }

    setActiveTab(index);
    if (index !== 4) {
      selectGame(null);
      setAdditionalPage(null)
    }
  }, [stage, setActiveTab]);

  const handleSelectGameId = useCallback((gameId: number, page: 'result' | 'templates') => {
    selectGame(gameId);
    setActiveTab(4);
    setAdditionalPage(page);
  }, []);

  const formNode = useMemo(() => {
    if (stage && activeTab === 1) {
      return (<TeamGroupsForm stage={stage} allTeams={teams || []} />);
    }

    if (stage && activeTab === 2) {
      return (
        <StageGrid stage={stage} />
      );
    }

    if (stage && activeTab === 3) {
      return (
        <StageMatches
          stage={stage}
          championshipId={championshipId}
          tournamentId={tournamentId}
          onShowResultsClick={(gameId) => handleSelectGameId(gameId, 'result')}
          onShowTemplatesClick={(gameId) => handleSelectGameId(gameId, 'templates')}
        />
      );
    }

    if (stage && selectedGameId && additionalPage && activeTab === 4) {
      switch (additionalPage) {
        case "result":
          return (
            <GameResult gameId={selectedGameId}/>
          );
        case "templates":
          const game = stage.matches.find((match) => match.id === selectedGameId);
          if (!game) return <Navigate to={'/tourneys'}/>
          return (
            <Template game={game} championship={championship} />
          );
      }
    }

    return (
      <StageInfoForm
        championshipId={championshipId}
        stage={stage}
      />
    );
  }, [stage, activeTab, selectedGameId, additionalPage, championshipId, teams, tournamentId, handleSelectGameId, championship]);

  return (
    <div>
      <div className="mb-5">
        <TabMenu
          tabs={tabs}
          activeTab={activeTab}
          onChange={handleChangeActiveTab}
        />
      </div>
      <div className="container">
        {formNode}
      </div>
    </div>
  );
};

export default StageForms;
