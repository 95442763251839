/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../components/text';
import { Dashboard } from './dashboard';
import { Fouls } from './fouls';
import { Period } from './period';
import { Score } from './score';
import Team from './team';
import { TeamsName } from './teamsName';
import CircleArrowCogwheelIcon from "../../../../ud-ui/components/icon/circle-arrow-cogwheel-icon";
import classNames from "classnames";
import { Button, HideButton, MainBlock, OpenPanelWrapper } from "./style";
import useGameInfo from "../../hooks/useGameInfo";
import { resetGameInfo, syncGameInfo } from "../../../store/gameInfo/actions";
import { resetTime, syncTime } from "../../../store/time/actions";
import SyncIcon from "../../../../ud-ui/components/icon/sync-icon";
import supabase from "../../../../core/supabase/supabase";
import Indicator from "../components/indicator";
import JoystickIcon from "../../../../ud-ui/components/icon/joystick-icon";
import { selectIsHideControlPanel } from "../../../store/overlay/selectors";
import { setHideControlPanel } from "../../../store/overlay/overlay";
import ArrowBracketIcon from "../../../../ud-ui/components/icon/arrow-bracket-icon";
import EmptyButton from "../../../../ud-ui/components/emptyButton";
import UDText from "../../../../ud-ui/components/text";
import { time } from "../../../domain/constants/time";
import { useParams } from "react-router-dom";

function Joystick() {
  const { matchId } = useParams() as { matchId: string }
  
  const dispatch = useDispatch()
  const { match, isSynchronization } = useGameInfo()
  const [isJoystickActive, setIsJoystickActive] = useState(true)
  const [ethernetConnection, setEthernetConnection] = useState(true)
  const [connectionStatus, setConnectionStatus] = useState(supabase.matchChronology.connectionStatus.value)
  const isHideControlPanel = useSelector(selectIsHideControlPanel)
  
  const [devDisableConnection, setDevDisableConnection] = useState(false)
  
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const timer = useRef<NodeJS.Timer>();
  
  useEffect(() => {
    supabase.matchChronology.connectionStatus.subscribe((data) => {
      setConnectionStatus(data)
    })
    
    const activeEthernet = () => {
      setEthernetConnection(true)
      console.debug({ event: 'internet connection recovered' })
    }
    const disableEthernet = () => {
      setEthernetConnection(false)
      console.error({ event: 'internet connection lost' })
    }
    
    window.addEventListener('online', activeEthernet)
    window.addEventListener('offline', disableEthernet)
    return () => {
      window.removeEventListener('online', activeEthernet)
      window.removeEventListener('offline', disableEthernet)
    }
  }, []);
  
  useEffect(() => {
    if (connectionStatus === 'SUBSCRIBED' && ethernetConnection) {
      setIsJoystickActive(true)
    } else {
      setIsJoystickActive(false)
    }
  }, [connectionStatus, ethernetConnection]);
  
  const onClickReset = useCallback(() => {
    dispatch(resetGameInfo())
    dispatch(resetTime())
  }, [dispatch]);
  
  const handleClickSync = useCallback(() => {
    if (isSynchronization) return
    if (!match) return
    const matchId = match.id
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
  }, [dispatch, isSynchronization, match]);
  
  useEffect(() => {
    if (connectionStatus !== 'SUBSCRIBED') {
      supabase.overlayLogs.createInfo({ message: 'Нет соединения с джойстиком. Включен режим запроса данных.' })
      timer.current = setInterval(() => {
        supabase.matchChronology.getRow(Number(matchId)).then(newData => {
          if (!newData) return
          const currentDate = new Date().toDateString()
          supabase.matchChronology.handleRecordUpdated({ new: newData, commit_timestamp: currentDate })
        })
      }, time.second * 3)
    } else {
      supabase.overlayLogs.createInfo({ message: 'Соединение с джойстиком установленно. Режим запроса данных выключен.' })
      clearInterval(timer.current)
    }
  }, [connectionStatus, matchId]);
  
  const toggleHideControlPanel = useCallback(() => {
    dispatch(setHideControlPanel(!isHideControlPanel))
  }, [dispatch, isHideControlPanel]);
  
  const handleDisconnect = useCallback(() => {
    setDevDisableConnection(prevState => {
      if (prevState) {
        supabase.matchChronology.observeChanges(Number(matchId))
      } else {
        supabase.matchChronology.stopObserveChanges().then(result => {
          console.debug({ disableConnection: result })
          if (result === 'ok') {
            setConnectionStatus('CLOSED')
          }
        })
      }
      
      return !prevState
    })
  }, [matchId]);
  
  return (
    isHideControlPanel ? (
      <>
        <div style={{ position: 'relative' }}/>
        <OpenPanelWrapper
          opacity={0}
          position={'right'}
        >
          <HideButton
            color={'white'}
            onClick={toggleHideControlPanel}
            icon={<ArrowBracketIcon
              style={{ zIndex: 1000 }}
              width={15}
              height={15}
              direction={isHideControlPanel ? 'right' : 'left'}
            
            />}
          />
        </OpenPanelWrapper>
      </>
    ) : (
      <>
        <MainBlock
          className={'d-flex flex-column'}
          style={{ zIndex: 99 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <EmptyButton
              style={{ backgroundColor: '#D9B244', height: '100%', width: 34, minWidth: 34 }}
              color={'white'}
              onClick={toggleHideControlPanel}
              icon={<ArrowBracketIcon style={{ zIndex: 1000 }} width={15} height={15} direction={'left'}/>}
            />
            <Indicator
              className={'w-100'}
              text={isJoystickActive ? 'Соединение с джойстиком установленно!' : 'Нет соединения с джойстиком!'}
              icon={<JoystickIcon/>}
              isActive={isJoystickActive}
            />
          </div>
          {isDev && (
            <Button
              color={devDisableConnection ? 'darkgreen' : 'darkred'}
              onClick={handleDisconnect}
              className={'flex-center'}
            >
              <Text size={'medium'} color={'inherit'}>
                {devDisableConnection ? 'Включить соединение' : 'Разорвать соединение'}
              </Text>
              <UDText type={"body"} style={{ color: 'inherit' }}>{devDisableConnection ? 'V' : 'X'}</UDText>
            </Button>
          )}
          <TeamsName/>
          <Score/>
          <Fouls/>
          <Period/>
          <Dashboard/>
          <Button
            color={'#D9B244'}
            onClick={handleClickSync}
            className={classNames('flex-center', { 'loading': isSynchronization })}
          >
            {!isSynchronization && (
              <>
                <Text size={'medium'} color={'inherit'}>Синхронизировать</Text>
                <SyncIcon height={13} width={12} color={'white'}/>
              </>
            )
            }
          </Button>
          <Button
            color={'#686A79'}
            onClick={onClickReset}
            className={'flex-center'}
          >
            <Text size={'medium'} color={'inherit'}>Сбросить все настройки</Text>
            <CircleArrowCogwheelIcon/>
          </Button>
          <Team teamIndex={0}/>
          <Team teamIndex={1}/>
        </MainBlock>
      </>
    )
  );
}

export default Joystick;
