import React, { useCallback, useEffect, useMemo } from 'react';
import UDMainLayout from "../../../../ud-ui/layout/main";
import { Pages } from "../../../../navigation/domain/enums/pages";
import DataPicker from "../../components/dataPicker";
import { ChampionshipHeader, FilterButton, GamesList, GamesWrapper, MainBlock } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { fetchCalendar } from "../../../store/actions";
import { useOrganizer } from "../../../../organizer/ui/hooks/useOrganizer";
import { useSchedule } from "../../hooks/useSchedule";
import { StyledGameCard, StyledGameDateHeader } from "../../../../games/ui/components/games-list/games-list";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { useNavigate } from "react-router-dom";
import UDText from "../../../../ud-ui/components/text";
import _ from "lodash";
import FilterIcon from "../../../../ud-ui/components/icon/filter-icon";
import useFilterModal from "./modals/filters";
import { selectFilter } from "../../../store/selectors";
import UDHeaderTitle from "../../../../ud-ui/components/header-title";


function Calendar() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { info, isLoading: isLoadingOrganizer } = useOrganizer()
  const { isLoading: isLoadingSchedule, selectDay, currentDate } = useSchedule()
  const filter = useSelector(selectFilter)
  
  useEffect(() => {
    if (!info) return
    dispatch(fetchCalendar({ tournamentId: info.id, monthNum: currentDate.month, yearNum: currentDate.year }));
  }, [currentDate.month, currentDate.year, dispatch, info]);
  
  const dateKey = useMemo(() => {
    return format(selectDay.date, 'dd MMMM, EEEEEE', { locale: ru })
  }, [selectDay.date]);
  
  const onShowGameTemplatesClick = useCallback((id: number) => {
    navigate(Pages.SCHEDULE.builders.view(id))
  }, [navigate]);
  
  const matches = useMemo(() => {
    return _(selectDay.matches).groupBy('championship.name').value()
  }, [selectDay.matches])
  
  const matchesByFilter = useMemo(() => {
    const championshipName = filter.championship;
    return championshipName === 'all' ? matches : matches[ championshipName ] ? { [ championshipName ]: matches[ championshipName ] } : {}
  }, [filter.championship, matches])
  
  const { open, modal } = useFilterModal()
  
  return (
    <UDMainLayout headerContent={<UDHeaderTitle>Расписание</UDHeaderTitle>}>
      <MainBlock>
        <div className={'d-flex'} style={{ height: 279, gap: 30, justifyContent: "space-between" }}>
          <DataPicker/>
        </div>
        {!(isLoadingOrganizer || isLoadingSchedule) && (
          Object.keys(matchesByFilter).length > 0 ? (
            <div className='mb-6 last-mb-0 w-100' style={{ overflow: 'hidden' }}>
              <StyledGameDateHeader style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                <p>{dateKey}</p>
                <FilterButton onClick={open}><FilterIcon/></FilterButton>
              </StyledGameDateHeader>
              <GamesWrapper>
                {_.map(matchesByFilter, (value, key) => (
                  <GamesList>
                    <ChampionshipHeader className={'ChampionshipHeader'}>
                      <UDText type={'subhead'} style={{ fontWeight: 'bold' }}>{key}</UDText>
                    </ChampionshipHeader>
                    {value.map((game) => (
                      <StyledGameCard
                        key={game.id}
                        game={game}
                        onShowTemplatesClick={onShowGameTemplatesClick}
                      />
                    ))}
                  </GamesList>
                ))}
              </GamesWrapper>
            </div>
          ) : (
            <div
              className={'flex-center w-100'}
              style={{
                flexDirection: 'column',
                backgroundColor: 'white',
                paddingBlock: 26,
                borderRadius: 8,
                height: 'fit-content',
                position: 'relative',
              }}
            >
              <FilterButton
                style={{ position: 'absolute', top: 8, right: 8, backgroundColor: '#f4f4f4' }}
                onClick={open}
              ><FilterIcon/></FilterButton>
              <UDText type={'callout'} className={'color-SurfaceRomanSilver30'} style={{ fontSize: 15 }}>
                На эти даты матчей нет.
              </UDText>
              <UDText type={'caption-1'}>
                <a
                  style={{ color: '#007AFF' }} target={'_blank'} href={'https://imgameapp.ru/#contacts'}
                  rel='noreferrer'
                >Сообщить об ошибке</a>
              </UDText>
            </div>
          )
        )}
      </MainBlock>
      {modal}
    </UDMainLayout>
  );
}

export default Calendar;
