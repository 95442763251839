/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UDMainLayout from "../../../ud-ui/layout/main";
import { history } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import backgroundElems from "../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import useLocalStorage from "../hooks/useLocalStorage";
import useCreateModal from "../hooks/modals/board/create";
import { manual } from "../../domain/consts/urls";
import useTemplates from "../hooks/useTemplates";
import useBoards from "../hooks/useBoards";
import { useDispatch } from "react-redux";
import { setActiveBoard } from "../../store/templates/templates";
import { setActiveObjects, setHasChanges, setSavedScheme } from "../../store/editor/editor";
import { MainBlock } from "./styles";
import UDHeaderViewBoards from "../../../ud-ui/components/fabric-canvas/view-boards/right/UDHeaderViewBoards";
import NewEditIcon from "../../../ud-ui/components/icon/new-edit-icon";
import BoardsList from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import {
  CanvasWrapper,
  Container,
  LeftContainer,
  RightContainer,
} from "../../../ud-ui/components/fabric-canvas/view-boards/styles";
import EmptyButton from "../../../ud-ui/components/emptyButton";
import PlusIcon from "../../../ud-ui/components/icon/plus-icon";
import ArrowRectangleIcon from "../../../ud-ui/components/icon/arrow-rectangle-icon";
import BoardItem from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";
import { useNavigate } from "react-router-dom";

function Boards() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isInit, setInit] = useState<boolean>(false)
  const { activeTemplate } = useTemplates()
  const { activeBoard, activeScheme, boards } = useBoards()
  const { canvas, manager } = useFabric('static', {
    data: { ...activeTemplate?.resolution },
    style: { borderRadius: 8, position: 'absolute' },
    resizing: true,
  })
  const { clearData } = useLocalStorage()
  
  useEffect(() => {
    clearData()
    dispatch(setActiveObjects([]))
    dispatch(setSavedScheme(undefined))
    dispatch(setHasChanges(false))
  }, [clearData, dispatch])
  
  useEffect(() => {
    if (!activeTemplate) {
      navigate(Pages.TEMPLATE.INDEX)
    }
  }, [activeTemplate, navigate]);
  
  
  useEffect(() => {
    if (!activeBoard || !activeTemplate || !activeTemplate.boards.find(board => board.id === activeBoard.id)) {
      if (!boards || boards.length <= 0) return;
      dispatch(setActiveBoard({ id: boards[ 0 ].id }))
    }
  }, [activeTemplate, activeBoard, boards, dispatch]);
  
  
  const isEditable = useMemo(() => {
    if (!activeTemplate) return false
    return activeTemplate.access !== 'default'
  }, [activeTemplate]);
  
  useEffect(() => {
    if (!manager) return
    backgroundElems.init(manager.canvas)
    setInit(true)
  }, [manager]);
  
  useEffect(() => {
    if (!manager) return
    if (!activeScheme) return
    manager.loadLocal({ scheme: activeScheme, safeBackground: true })
    manager.normalizedViewport()
  }, [activeBoard, activeScheme, manager])
  
  const onGoEdit = useCallback(() => {
    if (!isInit) return
    if (!activeBoard) return
    if (!isEditable) return;
    
    history.push(Pages.TEMPLATE.EDITOR, { from: 'boards' })
  }, [activeBoard, isEditable, isInit]);
  
  const breadcrumb = <UDBreadcrumbs
    className='my-10' breadcrumbs={[
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
    { label: activeTemplate?.name ?? 'Табло', to: Pages.TEMPLATE.CHOOSING },
  ]}
  />
  
  const { modal: createModal, open: openCreateModal } = useCreateModal()
  const redirectToManual = useCallback(() => {window.open(manual, '_blank');}, []);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <Container>
          <LeftContainer>
            <BoardsList
              items={
                boards.map((board) => {
                  return (
                    <BoardItem
                      key={board.id}
                      board={board}
                      isEditable={isEditable || boards.length <= 1}
                      onClick={() => dispatch(setActiveBoard({ id: board.id }))}
                      isActive={board.id === activeBoard?.id}
                    />
                  )
                })
              }
              headerText={'Табло'}
            />
            {isEditable && (
              <EmptyButton onClick={openCreateModal} color={'#A2A2A2'} icon={<PlusIcon/>}>
                Добавить табло
              </EmptyButton>
            )}
          </LeftContainer>
          <RightContainer>
            <UDHeaderViewBoards
              mainText={activeBoard?.name}
              buttons={[
                <EmptyButton
                  key={'edit'}
                  disabled={!isEditable}
                  onClick={onGoEdit}
                  icon={<NewEditIcon width={24} height={24}/>}
                >
                  Редактировать
                </EmptyButton>,
              ]}
              subElems={[
                <EmptyButton
                  key={'manual'}
                  onClick={redirectToManual}
                  icon={<ArrowRectangleIcon width={16} height={16}/>}
                >
                  Интсрукция
                </EmptyButton>,
              ]}
            />
            <CanvasWrapper>
              {canvas}
            </CanvasWrapper>
          </RightContainer>
        </Container>
      </MainBlock>
      {createModal}
    </UDMainLayout>
  );
}

export default Boards;
