import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ScheduleSliceState } from "./index";
import { fetchCalendar } from "./actions";
import { toast } from "react-toastify";
import { markGameDays } from "./utils/markGameDays";
import { markPassedDays } from "./utils/markPassedDays";
import { markToday } from "./utils/markToday";
import { markSelectedDay } from "./utils/markSelectedDay";

type ScheduleReducerBuilder = ActionReducerMapBuilder<ScheduleSliceState>;

export function createFetchTournamentDataReducer(builder: ScheduleReducerBuilder) {
  builder.addCase(fetchCalendar.pending, (state) => {
    state.isLoaded = false
    state.isLoading = true
  })
  
  builder.addCase(fetchCalendar.fulfilled, (state, action) => {
    state.isLoaded = true
    state.isLoading = false
    state.calendar = action.payload.calendar
    markGameDays(state);
    markPassedDays(state);
    markToday(state);
    markSelectedDay(state);
  })
  
  builder.addCase(fetchCalendar.rejected, (state) => {
    state.isLoaded = false
    state.isLoading = false
    
    toast.error('Не удалось загрузить данные')
  });
}
