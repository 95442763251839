import { useSelector } from "react-redux";
import { selectCalendar, selectCurrentDate, selectedDay, selectLoad, selectWeeks } from "../../store/selectors";

export function useSchedule() {
  const week = useSelector(selectWeeks);
  const currentDate = useSelector(selectCurrentDate);
  const { isLoading, isLoaded } = useSelector(selectLoad);
  const calendar = useSelector(selectCalendar);
  const selectDay = useSelector(selectedDay)
  
  return {
    week,
    currentDate,
    isLoaded,
    isLoading,
    calendar,
    selectDay,
  }
}
